.input-group {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.name-row input {
  padding: 6%;
}

input {
  text-align: left;
  padding: 2%;
  background-color: #fff;
  border: 1px solid #fff;
  width: auto;
  border-radius: 15px;
}

label {
  font-size: 1rem;
  color: #fff;
  text-align: left;
  margin-bottom: 5%;
}

.invalid {
  border: 1px solid red;
  background-color: rgb(252, 150, 150);
}

.invalid-paragraph {
  color: red;
  font-size: 1rem;
  margin: 5px auto;
}

@media screen and (width <= 500px) {
    .name-row input {
        padding: 2%;
    }
}