.brief-info-container {
  width: 100%;
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brief-info-container h4 {
  color: #fff;
  text-align: center;
}

.icons-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: 10%;
}

.info-icon {
  transition: all 0.5s ease-out;
}

.info-icon:hover {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 1);
}

@media screen and (width <= 1250px) {
  .brief-info-container h4 {
    text-align: center;
  }
}
