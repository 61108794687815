footer {
    padding: 5%;
    display: block;
    margin: 0 auto 0 25%;
    background-color: #000;
    text-align: center;
}

.footer-title {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-title h3 {
    color: #fff;
    margin: auto;
    margin-bottom: 2%;
    font-size: 1.5rem;
}

.footer-title h5 {
    color: #fff;
    margin: auto;
    font-size: 1rem;
    width: 50%;
}

/* Styles for adaptability */

@media screen and (width <= 1250px) {
    footer {
        width: 100%;
        margin: 0;
        padding: 5% 0;
        border: none;
    }
}

@media screen and (width <= 800px) {
    .footer-title h5 {
        width: 70%;
    }
}

@media screen and (width <= 500px) {
    .footer-title h5 {
        width: 80%;
    }
}