/* .website-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  max-width: 100%;
  overflow-x: hidden;
} */

.website-body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  display: block;
  color: white;
}

@media screen and (width <= 800px) {

}