.timeline-wrapper {
  margin-top: 10%;
}

.vertical-timeline-element-content {
    padding-top: 0 !important;
}

.icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.timeline-element-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: -2rem;
}

.timeline-element-wrapper h3 {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.timeline-element-wrapper h4 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
}

.skills-list-x {
  list-style-type: disc !important;
  margin: 0;
}

.skills-list li {
  font-size: 1rem;
}

.download-button {
  width: 100%;
  background-color: #000;
  text-align: center;
  outline: none;
  box-shadow: 4px 3px 1px 1px rgba(107, 100, 100, 0.25);
  border-radius: 15px;
  color: #fff;
  text-align: center;
  padding: 2% 10%;
  margin-top: 5%;
  transition: all 0.5s ease-out;
}

.download-button:hover {
  background-color: #9e9292;
  outline: #9e9292 solid 2px;
  outline-offset: 10px;
  color: #000;
}

.skill-caption {
  margin: 0 auto 2% !important;
  text-align: center;
  font-size: 0.75rem !important;
}
