header {
    position: absolute;
    top: 0;
    right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1% 5%;
}

select {
    background-color: #000000e9;
    border: none;
    color: #fff;
    padding: 10%;
    border-radius: 15px;
}

option {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
}

.flag-image {
    height: 2rem;
    aspect-ratio: 1 / 2;
    margin: 5% auto;
    z-index: 999;
}

@media screen and (width <= 1250px) {
    header {
        top: 20%;
        right: 30%;
    }
}

@media screen and (width <= 800px) {
    header {
        top: 15%;
        right: 30%;
    }
}