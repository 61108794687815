.card {
  border-radius: 45px;
  box-shadow: 16px 19px 19px -5px rgba(255, 255, 255, 0.81);
  background-color: #000;
  width: 70%;
  aspect-ratio: 3 / 1;
  margin-bottom: 10%;
  padding: 5%;
  display: flex;
  flex-direction: row;
}

@media screen and (width <= 1200px) {
  .card {
    margin-top: 10%;
  }
}

@media screen and (width <= 800px) {
  .card {
    margin-top: 25%;
  }
}

@media screen and (width <= 500px) {
  .card {
    margin-top: 35%;
    aspect-ratio: 3 / 1;
    width: 80%;
  }
}
