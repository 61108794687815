.left-side-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left-side {
  height: 100vh;
  width: 20%;
  background-color: #000;
  padding: 1% 5% 9% 5%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
}

.left-side ul {
  list-style-type: none;
  margin-top: 10%;
  padding: 0;
}

.left-side ul li {
  margin: 10% 0;
  font-size: 1.2rem;
  width: auto;
  padding-right: 0;
  border-radius: 45px;
  transition: all 0.5s ease-out;
}

li a {
  padding: 0 5%;
  text-decoration: none;
  color: #fff;
  transition: all 0.5s ease-out;
  border-radius: 45px;
  cursor: pointer;
}

.left-side ul li a:hover {
  color: #000;
  text-decoration: none;
  background-color: #fff;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 1);
}

.secondary-list {
  margin-left: 5%;
}

.secondary-list li {
  font-size: 1rem !important;
}

.dropdown-content-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
  color: #fff;
}

.dropbtn {
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
}


/* Styles for mobile */

@media screen and (width <= 1250px) {
  .left-side {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    padding: 2% 10%;
    height: auto;
    width: 80vw;
  }

  .left-side ul li a {
    font-weight: bold;
  }

  .left-side ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    width: 100%;
  }

  .left-side ul li {
    width: 100%;
    padding: 0;
    margin: 2%;
    font-size: 1rem;
    text-align: right;
  }

  .secondary-list li a {
    margin-right: 2rem !important;
  }

  .left-side ul li a:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 45px;
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.8);
  }
}

