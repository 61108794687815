.tools {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 10;
}

.tool {
  margin-bottom: 10%;
  width: 80%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}

.tool img {
  width: 50%;
  margin: 0 auto;
}

.tool p {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

.toolsTitle {
  margin: 5% auto 5% 2%;
  font-weight: bold;
}

@media screen and (width <= 750px) {
  .tools {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width <= 500px) {
  .tools {
    margin-left: 8%;
  }

  .toolsTitle {
    font-size: 1.5rem;
    margin: 5% auto;
  }
}
