.right-side {
  margin-left: 20%;
  background-color: #000000e9;
  padding: 15% 5% 9% 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Styles for mobile */

@media screen and (width <= 1250px) {
  .right-side {
    width: 100%;
    margin-left: 0;
    padding: 25% 5% 5% 5%;
  }
}

/* Media queries for changing picture size */

@media screen and (width <= 1300px) {
  .right-side {
    padding: 15% 2% 9% 10%;
  }
}

@media screen and (width <= 1100px) {
  .right-side {
    padding: 15% 1% 9% 5%;
  }
}

@media screen and (width <= 500px) {
  .right-side {
    width: 100%;
    padding: 15% 0;
  }
}
