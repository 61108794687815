.about-me-content-wrapper {
    margin: 5% 0;
    padding: 0;
    width: 100%;
}

.about-me-content-wrapper ul {
    list-style-type: upper-roman;
}

.about-me-content-wrapper ul li {
    margin: 2% auto;
}

.underlined {
    display: inline-block;
    text-decoration: underline;
    margin: 0;
}

