.general-info {
  width: 80%;
  color: white;
  opacity: 0;
  margin-left: 5%;
  transition: all 500ms ease-out;
}

.extension-button {
  margin: 5% auto;
  width: auto;
  padding: 2% 5%;
  background-color: #000;
  color: #fff;
  font-size: 18;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  box-shadow: 5px 5px 10px 10px rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-out;
}

.extension-button:hover {
  background-color: #fff;
  color: #000;
  border-radius: 45px;
  box-shadow: 5px 5px 10px 10px rgb(76, 71, 71);
}

.fade-in {
  animation: slide-in;
}

.fade-out {
  animation: slide-out;
}

.shown {
  opacity: 1;
}

@media screen and (width <= 500px) {
  .extension-button {
    width: 80%;
    margin: 1% auto;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
