.caption-education {
    font-size: 1rem;
    color: #fff;
    text-align: left;
    margin: 1% auto 1% 2%;
}

.underline {
    text-decoration: underline;
    cursor: pointer;
}

.exam-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 1% auto 1% 2%;
}

.exam-list {
    list-style-type: disc !important;
    margin-top: 0;
}