.course-cell {
  width: 40%;
}

.logo-cell {
  width: 20%;
  padding: 0;
}

.skill-cell {
  width: 20%;
}

.logo-cell img {
  width: 60%;
  border-radius: 90px;
  background: no-repeat scroll center center transparent;
}

table {
  text-align: center;
  margin: 5% auto 15%;
  box-sizing: border-box;
  margin-bottom: 0;
}

thead {
  font-weight: 600;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
}

thead th {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  font-size: 1.25rem;
}

table td {
  padding: 2%;
  border-bottom: 2px solid #fff;
}

.caption {
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
  margin: 0.5% auto;
  margin-bottom: 10%;
}

.download-btn {
  all: unset;
  background-color: #000;
  text-align: center;
  outline: none;
  box-shadow: 4px 3px 1px 1px rgba(107, 100, 100, 0.25);
  border-radius: 15px;
  color: #fff;
  text-align: center;
  padding: 2% 10%;
  transition: all 0.5s ease-out;
}

.download-btn:hover {
  background-color: #9e9292;
  outline: #9e9292 solid 2px;
  outline-offset: 10px;
  color: #000;
}

.course-title {
  font-weight: normal;
  font-size: 1rem;
}
